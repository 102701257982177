<template>
    <el-container>
        <el-row style="width: 100%">
            <el-col :span="24">
                <el-form ref="form" :model="form" label-width="100px" label-position="right" size="mini">
                    <el-form-item label="项目名称" prop="name">
                        <el-input v-model="form.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="点位">
                        <el-card shadow="none">
                            <el-button size="mini" @click="addQuestionType">添加点位</el-button>
                            <template v-if="surveyLength">
                                <el-button type="success" @click="dataAndFileAndQrcodeAllDownload('file')">下载全部文件</el-button>
                                <el-button type="success" @click="dataAndFileAndQrcodeAllDownload('qrcode')">下载全部二维码</el-button>
                                <el-button type="success" @click="dataAndFileAndQrcodeAllDownload('data')">项目数据统计</el-button>
                            </template>
                            <el-divider></el-divider>
                            <el-table :data="form.surveyList" style="width: 100%">
                                <el-table-column label="点位名称" prop="name" width="270px">
                                </el-table-column>
                                <el-table-column label="已收数量/应收数量" width="150px">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.actual+'/'+scope.row.target }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <el-row>
                                            <!-- 数据统计 -->
                                            <el-col :span="3">
                                                <el-button size="small" type="primary" @click="dataAndFileAndQrcodeDownload(scope.row,'data')">数据统计</el-button>
                                            </el-col>
                                            <!-- 修改文件 -->
                                            <el-col :span="3">
                                                <el-upload v-if="scope.row.filePath"  :show-file-list="false" action :data="scope.row" :http-request="fileEdit">
                                                    <el-button size="small" type="primary">修改文件</el-button>
                                                </el-upload>
                                            </el-col>
                                            <!-- 下载文件 -->
                                            <el-col :span="3">
                                                
                                                <el-button v-if="scope.row.filePath"  size="small" type="primary" @click="dataAndFileAndQrcodeDownload(scope.row,'file')">下载文件</el-button>
                                            </el-col>
                                            <!-- 组试卷 -->
                                            <el-col :span="3">
                                                <el-button v-if="!scope.row.filePath" size="small" type="primary" @click="goQuestion(scope.row)">组试卷</el-button>
                                            </el-col>
                                            <!-- 下载二维码 -->
                                            <el-col :span="3">
                                                <el-button size="small" type="primary" @click="dataAndFileAndQrcodeDownload(scope.row,'qrcode')">下载二维码</el-button>
                                            </el-col>
                                            <!-- 编辑 -->
                                            <el-col :span="2" :offset="1">
                                                <el-button size="small" type="primary" @click="changeName(scope.row)">编辑</el-button>
                                            </el-col>
                                            <!-- 复制 -->
                                            <el-col :span="2">
                                                <el-button size="small" type="info" @click="copyQuertion(scope.row)">复制</el-button>
                                            </el-col>
                                            <!-- 删除 -->
                                            <el-col :span="2">
                                                <el-button size="small" type="danger" @click="removeQuestion(scope.row.guid,scope.row.name)">删除</el-button>
                                            </el-col>
                                        </el-row>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-dialog title="添加点位" width="30%" :visible.sync="questionTypeVisible" >
            <el-form :model="form">
                <el-form-item label="点位名称">
                    <el-input v-model="questionName" @change="nameChange"></el-input>
                </el-form-item>
                <el-form-item label="应收数量">
                    <el-input v-model="target" type="number"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-upload ref="upload" :data="{name: questionName}" action :http-request="selectFile">
                        <el-button size="small" type="primary">选择文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="questionTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveQuestionAndFile('yes')">确 定(有文件)</el-button>
                <el-button type="primary" @click="saveQuestionAndFile('no')">确 定(无文件)</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改点位名称" width="30%" :visible.sync="editQuestionTypeVisible" >
            <el-form :model="form">
                <el-form-item label="点位名称">
                    <el-input v-model="questionName"></el-input>
                </el-form-item>
                <el-form-item label="应收数量">
                    <el-input v-model="target" type="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editQuestionTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="editQuestionName">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="复制点位" width="50%" :visible.sync="copyQuertionVisible" >
            <el-form :model="copyForm" ref="copyForm" label-width="100px">
                <el-form-item :label="'点位名称' + (index+1)" v-for="(item, index) in copyForm.copyNameList" :key="index">
                    <el-input v-model="item.name" style="width: 50%"></el-input><i class="el-icon-delete" style="margin-left: 10px;cursor:pointer" @click="delItem(index)"></i>
                </el-form-item>
                <el-form-item>
                     <el-button @click="addItem">新增点位名称</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="copyQuertionVisible = false">取 消</el-button>
                <el-button type="primary" @click="doCopyQuertion">确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            form: {},
            copyForm: {
                copyNameList: [{ name: '' }],
                surveyGuid: ''
            },
            fileForm: new FormData(),
            surveyLength:'',
            questionName: '',
            questionGuid: '',
            projectGuid: "",
            questionTypeVisible: false,
            editQuestionTypeVisible: false,
            copyQuertionVisible: false,
            tabPosition: "left",
            name: '',
            dwType: '',
            target: null
        }
    },
    created() {
        let self = this;
        self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        self.getSurveyDetail(self.$route.query.guid || null);
        self.projectGuid = self.$route.query.guid || null
    },
    methods: {
        // 获取详情
        getSurveyDetail(guid) {
            let self = this
            self.$axios.get("/survey-project/detail/" + guid, {
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.form = res.data.data
                self.surveyLength = self.form.surveyList.length
                console.log(self.form);
            })
            .catch(function(error) {
                self.$message({
                    message: error.response.data.message,
                    type: "warning",
                });
            });
        },
        // 添加点位按钮
        addQuestionType() {
            let self = this
            self.questionName = ''
            self.target = null
            self.questionTypeVisible = true
        },
        // 选择文件
        selectFile(files) {
            let self = this
            console.log('files=',files.files);
            let fileForm = new FormData()
            fileForm.append('file', files.file)
            fileForm.append('name', files.data.name)
            fileForm.append('projectGuid', self.projectGuid)
            fileForm.append('target', self.target)
            self.fileForm = fileForm
        },
        nameChange() {
            this.fileForm.set("name", this.questionName);
            console.log(this.fileForm);
        },
        // 添加点位
        saveQuestionAndFile(type) {
            let self = this
            let data ={}
            let url = ''
            let header = {}
            if(type=='yes') {
                data = self.fileForm
                url = '/survey/save'
                header = {token: self.userInfo.token, 'Content-Type':'multipart/form-data'}
            } else if(type=='no') {
                let dataNo = {
                    projectGuid: self.projectGuid,
                    name: self.questionName,
                    target: self.target
                }
                data = self.$qs.stringify(dataNo)
                url = '/survey/save2'
                header = {token: self.userInfo.token, 'Content-Type':'application/x-www-form-urlencoded'}
            }
            self.$axios.post(url, data,{
                headers: header
            })
            .then(function(res) {
                self.$message({
                    message:res.data.message,
                    type: "success",
                });
                self.getSurveyDetail(self.projectGuid)
                self.$refs.upload.clearFiles()
                self.questionTypeVisible = false
            })
            .catch(function (error) {
                self.$message({
                    message:error.response.data.message,
                    type: "warning",
                });
            });
        }, 
        // 修改点位名称
        changeName(row) {
            let self = this
            self.questionName = row.name
            self.questionGuid = row.guid
            self.target = row.target
            self.editQuestionTypeVisible = true
        },
        // 修改点位名称确认
        editQuestionName() {
            let self = this
            self.$axios.put("/survey/modify/name",{
                 name: self.questionName,guid: self.questionGuid,target: self.target
            },{
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.$message({
                    message: res.data.message,
                    type: "success",
                });
                self.editQuestionTypeVisible = false
                self.getSurveyDetail(self.projectGuid)
            })
            .catch(function(error) {
                self.$message({
                    message: error.response.data.message,
                    type: "warning",
                });
            });
        },
        // 修改文件
        fileEdit(files) {
            let self = this
            let form = new FormData()
            console.log(files);
            form.append('file', files.file)
            form.append('surveyGuid', files.data.guid)
            self.$axios.put("/survey/updateData", form ,{
                headers: { token: self.userInfo.token, 'Content-Type':'multipart/form-data' }
            })
            .then(function() {
                self.$message({
                    message:"修改成功",
                    type: "success",
                });
                self.getSurveyDetail(self.projectGuid)
            })
            .catch(function (error) {
                self.$message({
                    message:error.response.data.message,
                    type: "warning",
                });
            });
        },
        // 下载单个文件或二维码或数据统计
        dataAndFileAndQrcodeDownload(row, type) {
            // let self = this
            let guid = row.guid
            let url
            if(type == 'file') {
                url = this.GLOBAL.host + "/survey/" + type + "/download?surveyGuid=" + guid
            } else if (type == 'qrcode') {
                url = this.GLOBAL.host + "/survey/" + type + "/download?surveyGuid=" + guid
            } else if (type == 'data') {
                url = this.GLOBAL.host + "/survey/data?surveyGuid=" + row.guid
            }
            var a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.click();
        },
        // 复制点位
        copyQuertion(row) {
            let self = this
            self.copyQuertionVisible = true
            self.copyForm.surveyGuid = row.guid
            self.copyForm.copyNameList = [{ name: '' }]
        },
        addItem() {
            let self = this
            self.copyForm.copyNameList.push({
                name: '',
            });
        },
        delItem(index) {
            let self = this
            self.copyForm.copyNameList.splice(index,1)
            console.log(index);
        },
        // 复制点位确认
        doCopyQuertion() {
            let self = this
            let form = self.copyForm.copyNameList
            let nameList = []
            let guid = self.copyForm.surveyGuid
            form.forEach(item => {nameList.push(item.name)})
            console.log(self.copyForm.surveyGuid);
            console.log(form);
            console.log(nameList);
            self.$axios.post("/survey/copy",{
                nameList: nameList,
                surveyGuid: guid
            },{
                headers: { token: self.userInfo.token}
            })
            .then(function(res) {
                self.$message({
                    message: res.data.message,
                    type: "success"
                });
                self.copyQuertionVisible = false
                self.getSurveyDetail(self.projectGuid)
            })
            .catch(function(error) {
                self.$message({
                    message: error.response.data.message,
                    type: "warning"
                });
            });
        },
        // 删除点位
        removeQuestion(guid,title) {
            let self = this
            self.$confirm("确定删除" + title + "吗？")
            .then(() => {
                self.$axios.delete("/survey/delete", {
                    data: {guid: guid},
                    headers: { token: self.userInfo.token}
                })
                .then(function() {
                    self.$message({
                        message:"删除成功",
                        type: "success",
                    });
                    self.getSurveyDetail(self.projectGuid)
                })
                .catch(function () {
                    self.$message({
                        message:"删除失败",
                        type: "warning",
                    });
                });
            })
            .catch(() => {}); 
        },
        // 下载全部文件或二维码或数据统计
        dataAndFileAndQrcodeAllDownload(type) {
            let guid = this.projectGuid
            console.log(guid);
            let url
            if(type == 'file') {
                url = this.GLOBAL.host + "/survey/" + type + "/batch?surveyProjectGuid=" + guid
            } else if (type == 'qrcode') {
                url = this.GLOBAL.host + "/survey/" + type + "/batch?surveyProjectGuid=" + guid
            } else if(type == 'data') {
                url = this.GLOBAL.host + "/survey/project/data?projectGuid=" + guid
            }
            var a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.click();
        },
        // 组试卷
        goQuestion(row) {
            let self = this
            let guid = row.guid
            console.log(row);
            self.$router.push({
            name: 'project_satisfaction_question',
            query: { guid: guid, name: row.name},
            });
        }
    }
}
</script>

<style>
</style>
